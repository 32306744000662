.heroHeader{
    position: relative;
    height: auto;
    width: max-content;
    padding: 0px;
    margin: 24px 0px 24px -5px;
    color: #fd1d86;
    }
.heroHeader h1>*{
    padding: 0px !important;
    position: relative;
    display: block;
    font-size: 96px;
    line-height: 1em;;
    }
    .heroHeader h1 .adj1{
        margin-top: 0px;
        font-size: 48px;
        margin-left: 44px;
        }
    .heroHeader h1 .adj2{
        margin-top: -35px;
        margin-left: 0px;
        font-size: 73px;
        }
.heroHeader h1 .adj3{
    margin-top: -4px;
    margin-left: 15px;
    font-size: 96px;
    line-height: 0.5em;
    }
@media only screen and (min-width:480px){
.heroHeader h1 .adj1{
    margin-top: 0px;
    font-size: 48px;
    margin-left: 58px;
    }
.heroHeader h1 .adj2{
    margin-top: -41px;
    margin-left: 0px;
    font-size: 96px;
    }
.heroHeader h1 .adj3{
    margin-top: -7px;
        margin-left: 22px;
        font-size: 120px;
        line-height: 0.5em;
    }
}