.heroHeader{
    position: relative;
    height: auto;
    width: max-content;
    padding: 0px;
    margin: 24px 0px 24px -5px;
    color: #fd1d86;
    }
.heroHeader h1>*{
    position: relative;
    display: block;
    padding: 0px;
    line-height: 1em;
    }
.heroHeader h1 .adj1{
    margin-top: 0px;
    font-size: 48px;
    margin-left: 90px;
    }
.heroHeader h1 .adj2{
    margin-top: -41px;
    margin-left: 0px;
    font-size: 96px;
    }
.heroHeader h1 .adj3{
    margin-top: -9px;
    margin-left: 59px;
    font-size: 96px;
    line-height: 0.5em;
    }
.kernA{
    letter-spacing: -0.04em;
    }
.kernAtight{
    letter-spacing: -0.13em;
    }

@media only screen and (min-width:768px){
.heroHeader h1 .adj1{
    margin-top: 0px;
    font-size: 48px;
    margin-left: 90px;
    }
.heroHeader h1 .adj2{
    margin-top: -41px;
    margin-left: 0px;
    font-size: 96px;
    }
.heroHeader h1 .adj3{
    margin-top: -9px;
    margin-left: 59px;
    font-size: 96px;
    line-height: 0.5em;
    }
}