h1.darkBackground{
    /*color: #eabfba;*/
    /*color: rgb(255, 87, 166);*/
    color: #d8d2c2;
    text-shadow: 0px 0px 30px rgba(0, 0, 0, 1), 0px 0px 60px rgba(0, 0, 0, 1);
    }
h1.home{
    font-size: 29px;
    line-height: 1.15em;
    }
.landingPageTop{
    position: relative;
    top: 0px;
    margin-top: 0px;
    min-height: 100vh;
    max-height: 100vh;
    width: 100%;
    max-width: 100%;
    padding: 0px;
    }
.landingPageTopInner{
    position: relative;
    display: block;
    width: 100%;
    margin:0px auto !important;
    padding:50vh 18px 0px 18px;
    /*transform: translateY(-10%);*/
    height: auto;
    }
.landingTextImg{
    position: relative;
    display: block;
    }
.landingPageTopTextBlock{
    max-width: 200px;
    }
.landingBackground{
    background-image: url(../../images/home/mark-belford-home-1-xs.webp);
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;
    }
.homeSectionBTF1{
    padding-top: 123px;
    padding-bottom: 99px;
    }
.belowTheFoldHeader{
    position: relative;
    margin: 0px auto;
    height: auto;
    width: max-content;
    padding: 0px;
    color: #fd1d86;
    padding-bottom: 47px;
    }
.belowTheFoldHeader h1>*{
    position: relative;
    display: block;
    padding: 0px;
    line-height: 1em;
    width: fit-content;
    }
.belowTheFoldHeader h1 .adj1{
    margin-top: 0px;
    font-size: 34px;
    left: 16px;
    letter-spacing: -0.05em;
    }
.belowTheFoldHeader h1 .adj2{
    margin-top: -12px;
    left: 0px;
    font-size: 55px;
    letter-spacing: -0.012em;
    }
.belowTheFoldHeader h1 .adj3{
    margin-top: -12px;
    left: 57px;
    font-size: 55px;
    letter-spacing: -0.012em;
    }
.belowTheFoldHeader h1 .adj4{
    margin-top: -36px;
    left: 5px;
    font-size: 89px;
    letter-spacing: -0.024em;
    }
.sketch{
    position: relative;
    margin: 0px auto;
    width: 48px;
    opacity: 0.8;
    }
.contentTopParagraph{
    padding-top: 0px;
    text-align: center;
    font-family: 'HCo Gotham SSm';
    }
.fullBleedFlushLeft {
    position: relative;
    display: block;
    }
.halfPageRight {
    display: block;
    position: absolute;
    width: 100%;
    /* background-color: #ecdfdb; */
    max-width: 1220px;
    margin: 0px auto;
    left: 0px;
    right: 0px;
    top: 48%;
    transform: translateY(-50%);
    }
.imageLinkCaption{
    position: relative;
    display: block;
    text-transform: uppercase;
    font-size: 9px;
    bottom: 42px;
    left: 12px;
    font-family: 'HCo Gotham SSm Book';
    color: #111;
    width: auto;
    }
.imageLinkCaption .line1{
    position: relative;
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    padding-bottom: 6px;
    font-family: 'HCo Gotham SSm Book';
    color: #fff;
    }
.imageLinkCaption .line2{
    position: relative;
    display: block;
    text-transform: uppercase;
    font-size: 24px;
    font-family: "BenguiatCaslonRegular";
    color: rgb(253, 29, 134);
    }
.imageLinkCaption .line3{
    position: relative;
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1em;
    font-family: 'HCo Gotham SSm Book';
    color: #111;
    }
@media only screen and (min-width:576px){
.landingBackground{
    background-image: url(../../images/home/mark-belford-home-1-sm.webp);
    background-position: center center;
    }
.belowTheFoldHeader h1 .adj1{
    margin-top: 0px;
    font-size: 72px;
    left: 54px;
    letter-spacing: -0.02em;
    }
.belowTheFoldHeader h1 .adj2{
    margin-top: -44px;
    left: 0px;
    font-size: 96px;
    letter-spacing: -0.012em;
    }
.belowTheFoldHeader h1 .adj3{
    margin-top: -26px;
    left: 90px;
    font-size: 72px;
    letter-spacing: -0.012em;
    }
.belowTheFoldHeader h1 .adj4{
    margin-top: -65px;
    left: 6px;
    font-size: 144px;
    letter-spacing: -0.024em;
    }
.landingPageTopInner{
    padding:50% 34px 0px 34px;
    }
.landingPageTopTextBlock{
    max-width: 100%;
    }
}
@media only screen and (min-width:768px){
h1.home{
    font-size: 34px;
    }
.landingPageTopInner{
    padding: 50% 55px;
    }
.landingBackground{
    background-image: url(../../images/home/mark-belford-home-1-md.webp);
    background-position: center center;
    }
.fullBleedFlushLeft {
    width: 66.66666667vw;
    margin-left: calc(66.66666667% - 66.66666667vw);
    padding-left: 24px;
    }
}
@media only screen and (min-width:992px){
h1.home{
    font-size: 55px;
    line-height: 1.1em;
    }
.landingPageTopInner{
    max-width: 843px;
    padding: 50% 0px 0px 0px;
    transform: translateY(-40%);
    }
.fullBleedFlushLeft {
    width: 58.33333333vw;
    margin-left: calc(58.33333333% - 58.33333333vw);
    padding-left: 24px;
    }
.imageLinkCaption{
    float: right;
    right: 24px;
    left: unset;
    }
}
@media only screen and (min-width:1200px){
h1.darkBackground{
    line-height: 1.02em;
    }
.landingBackground{
    background-image: url(../../images/home/mark-belford-home-1.webp);
    background-position: center center;
    }
.fullBleedFlushLeft {
    width: 50vw;
    margin-left: calc(50% - 50vw);
    padding-left: 24px;
    }
.halfPageRight {
    display: block;
    position: absolute;
    width: 100%;
    max-width: 1220px;
    margin: 0px auto;
    left: 0px;
    right: 0px;
    top: 48%;
    transform: translateY(-50%);
    }
.landingPageTopInner{
    max-width: 987px;
    padding-top: 50% !important;
    transform: translateY(-40%);
    }
}
@media only screen and (min-width:1400px){

.landingPageTopInner{
    max-width: 1152px;
    padding-top: 50vh !important;
    /*transform: translateY(-50%);*/
    }    
}
@media only screen and (min-width:1600px){
.landingPageTopInner{
    max-width: 1364px;
    }
}
@media only screen and (min-width:1800px){
.landingPageTopInner{
    max-width: 1597px;
    }
}
@media only screen and (min-width:576px) and (orientation: portrait){
.landingBackground{
    background-image: url(../../images/home/mark-belford-home-1-sm-port.webp);
    background-position: center center;
    }
}
@media only screen and (min-width:992px) and (orientation: portrait){
.landingBackground{
    background-image: url(../../images/home/mark-belford-home-1.webp);
    background-position: center center;
    }
}