@font-face {
	font-family: 'HCo Gotham SSm';
	font-display: swap;
	src:url('../../Webfonts/woff2/GothamSSm-XLight_Web.woff2') format('woff2'),
		url('../../Webfonts/woff/GothamSSm-XLight_Web.woff') format('woff');
	font-weight: 200;
	font-style: normal;
}
@font-face {
	font-family: 'HCo Gotham SSm Light';
	font-display: swap;
	src:url('../../Webfonts/woff2/GothamSSm-Light_Web.woff2') format('woff2'),
		url('../../Webfonts/woff/GothamSSm-Light_Web.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'HCo Gotham SSm Book';
	font-display: swap;
	src:url('../../Webfonts/woff2/GothamSSm-Book_Web.woff2') format('woff2'),
		url('../../Webfonts/woff/GothamSSm-Book_Web.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'HCo Gotham SSm Medium';
	font-display: swap;
	src:url('../../Webfonts/woff2/GothamSSm-Medium_Web.woff2') format('woff2'),
		url('../../Webfonts/woff/GothamSSm-Medium_Web.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}
@font-face {
  font-family: "BenguiatCaslonRegular";
  font-display: swap;
  src: url('../../Webfonts/BenguiatCaslonRegular/font.woff2') format('woff2'),
    url('../../Webfonts/BenguiatCaslonRegular/font.woff') format('woff');
}
body{
  position: relative;
  height: 100%;
  width: 100%;
  background-color: rgb(244, 242, 237);
  color: #494745;
  /*font-family: montserrat, sans-serif;*/
  font-family: 'HCo Gotham SSm Light';
  font-size: 16px;
  line-height: 24px;
  overflow-x: hidden;
}
html, body, #app, #app>div {
  min-height: 100vh;
  max-height: 100vh;
}
* {
  text-decoration: none !important;
  list-style: none;
  border: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -ms-appearance: none;
}
.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
h1{
  font-size: 55px;
  line-height: 0.89em;
  font-family: "BenguiatCaslonRegular";
  padding: 0px;
  margin: 0px;
  }
h1.pull-left{
  margin-left: -3px;
}
h2{
  font-size: 47px;
  line-height: 0.9em;
  font-family: "BenguiatCaslonRegular";
  padding: 0px;
  margin: 0px;
  padding-bottom: 24px;
}
h3{
  font-size: 34px;
  line-height: 0.9em;
  font-family: "BenguiatCaslonRegular";
  padding: 0px;
  margin: 0px;
}
h4{
  font-size: 29px;
  line-height: 0.9em;
  font-family: "BenguiatCaslonRegular";
  padding: 0px;
  margin: 0px;
}
h5{
  font-size: 21px;
  line-height: 1.1em;
  padding: 0px;
  margin: 0px;
}
h6{
  font-size: 18px;
  line-height: 1.3em;
  padding: 0px;
  margin: 0px;
  letter-spacing: -0.02em;
}
p{
  padding: 0px;
  margin: 0px;
  padding: 0px;
  margin: 0px;
}
p.top-adj-1{
  margin-top: -6px;
}
.no-wrap{
  white-space: nowrap;
}
.sml{
  display: block !important;
}
.sml-inline{
  display: inline-block !important;
}
.lrg{
  display: none !important;
}
.lrg-inline{
  display: none !important;
}v
.sml-1{
  display: block !important;
}
.sml-1-inline{
  display: inline-block !important;
}
.lrg-1{
  display: none !important;
}
.lrg-1-inline{
  display: none !important;
}
.rag-left{
  text-align: right;
  }
.align-flex-bottom{
  align-items: flex-end;
}
.light-pink{
  color: #f6ddda;
  }
.hot-pink{
  color:#fd1d86 !important;
}
.med-grey{
  color:#706e6c;
}
.pad-bottom-24{
  padding-bottom: 24px;
  }
.content-right{
  justify-content: right;
  }
.content-space-between{
  justify-content: space-between;
}
.text-align-right{
  text-align: right;
  }
main{
  position:relative;
  height: 100%;
  width: 100%;
}
.initials{
  display: block;
  position: absolute;
  right: 30px;
  bottom: 24px;
  font-size: 110px;
  color: #f6ddda;
  line-height: 1em;
  z-index: -1;
  font-family: bodoni-pt-variable, sans-serif;
  font-style: normal;
  font-variation-settings: "opsz" 60, "wght" 700;
  }
.image{
  display: block;
  position: relative;
  padding-bottom: 18px;
  }
.image-full{
  display: block;
  position: relative;
  }
.image-full::after {
  content: '';
    width: 50vw;
    padding-right: 24px;
    background-color: rgb(0, 0, 0);
    display: none;
    height: 100%;
    position: absolute;
    top: 0px;
    right: calc(-50vw + 24px);
    z-index: -1;
  }
.relative{
  position: relative;
  }
.initials-small{
  display: block;
    position: absolute;
    width: 144px;
    margin-top: -90px;
    margin-left: 50px;
    z-index: 0;
  }
.e-comerce-link-2{
  display: block;
  position: relative;
  width: max-content;
  margin: 24px 0px;
  transition: all ease 300ms;
  cursor: pointer;
  border-top: 1px solid rgba(253, 29, 134, 0);
  border-bottom: 1px solid rgba(253, 29, 134, 0);
  }
.e-comerce-link-2::after{
    content:'';
    position: absolute;
    display: block;
    height: 96px;
    width: 1px;
    bottom: -14px;
    background-color: rgba(253, 29, 134, 0);
    transform: rotate(45deg);
    right: -100px;
    transition: all ease-in-out 200ms;
    transition-delay: 20ms;
    pointer-events: none;
    z-index: 500;
    }
.e-comerce-link-2::before{
    content:'';
    position: absolute;
    display: block;
    height: 150px;
    width: 0px;
    bottom: -29px;
    background-color: rgba(244, 242, 237, 1);
    transform: rotate(45deg);
    right: -100px;
    transition: all ease-in-out 220ms;
    pointer-events: none;
    z-index: 500;
    }
.e-comerce-link-2 a{
    position: relative;
    display: block;
    color: rgb(253, 29, 134);
    font-family: "BenguiatCaslonRegular";
    font-size: 36px;
    line-height: 0.9em;
    padding: 7px 0px 3px 0px;
    text-decoration: none;
    z-index: 300;
    }
.e-comerce-link-2:hover::after{
    background-color: rgba(253, 29, 134, 1);
    transform: rotate(45deg);
    right: 50px;
    }
.e-comerce-link-2:hover::before{
    background-color: rgba(244, 242, 237, 1);
    transform: rotate(45deg);
    width: 45px;
    right: -17px;
    }
.e-comerce-link-2 .hidden-text{
    opacity: 0;
    display: block;
    position: absolute;
    bottom: -32px;
    right: -62px;
    line-height: 0.9em;
    font-family: "BenguiatCaslonRegular";
    font-size: 36px;
    color: rgba(253, 29, 134, 1);
    z-index: 700;
    mask: url(../../images/elements/hidden-text-mask.svg) -45px 60px / 400px repeat-y exclude;
    transition: all ease-in-out 300ms;
    transition-delay: 50ms;
    }
.e-comerce-link-2 .hidden-text.on{
    opacity: 1;
    }
.e-comerce-link-3{
  display: block;
  position: relative;
  width: max-content;
  margin: 24px 0px;
  transition: all ease 300ms;
  cursor: pointer;
  }
.e-comerce-link-3::after{
  content:'';
  position: absolute;
  display: block;
  height: 96px;
  width: 1px;
  bottom: -19px;
  background-color: rgba(253, 29, 134, 0);
  transform: rotate(36deg);
  right: 250px;
  transition: all ease-in-out 200ms;
  transition-delay: 20ms;
  pointer-events: none;
  }
.e-comerce-link-3::before{
  content:'';
  position: absolute;
  display: block;
  height: 150px;
  width: 10px;
  bottom: -19px;
  background-color: rgba(11, 11, 11, 1);
  transform: rotate(36deg);
  right: 230px;
  transition: all ease-in-out 220ms;
  pointer-events: none;
  }
.e-comerce-link-3 a{
  color: rgb(253, 29, 134);
  font-family: "BenguiatCaslonRegular";
  font-size: 36px;
  line-height: 0.9em;
  padding: 7px 0px 3px 0px;
  text-decoration: none;
  }
.e-comerce-link-3:hover::after{
  background-color: rgba(253, 29, 134, 1);
  transform: rotate(36deg);
  right: 54px;
  }
.e-comerce-link-3:hover::before{
  background-color: rgba(11, 11, 11, 1);
  transform: rotate(36deg);
  right: 28px;
  }
.txt-img-align-top{
  margin-top: -6px;
  }
.content-top{
  padding-top: 144px !important;
  min-height: 100vh;
  width: 100%;
  }
.content-top.large-adjust{
  padding-top: 96px !important;
  }
.content{
  position: relative;
  display: block;
  width: 100%;
  margin: 0px auto;
  padding: 24px 18px;
  }
.content.top-block{
  padding-top: 144px !important;
  }
.content.bottom-block{
  padding-bottom: 96px !important;
  }
.content.bottom{
  padding: 0px 36px 60px 36px;
  }
.content.middle-big-pad{
  padding: 36px 18px 36px 18px;
  }
.content.full-page{
  max-width: 100%;
  padding: 24px 36px 24px 36px;
  display: block;
  }
.full-screen-top {
  position: relative;
  top: 0px;
  margin-top: 0px;
  min-height: 100vh;
  max-height: 100vh;
  width: 100%;
  max-width: 100%;
  }
.half-page-left{
  display: inline-block;
  position: relative;
  width: 100%;
  }
.half-page-right{
  display: inline-block;
  position: relative;
  width: 100%;
  }
.half-page-text-block{
  position: relative;
  padding-top: 24px;
  }
.vertical-center-block{
  display: block;
  position: relative;
  margin-top: 24px;
  }
.big-quote{
  font-size: 60px;
  line-height: 60px;
  font-family: "BenguiatCaslonRegular";
  font-variation-settings: "opsz" 60, "wght" 700;
  }
.call-out-text{
  line-height: 0.89em;
  color:#fd1d86;
  }
#anchor{
  position: relative;
  display: block;
  background-color: red;
  height: 30px;
  width: 30px;
}
.page-marker{
  position: absolute;
  height: 30px;
  width: 50%;
  top: 150px;
  } 
/* ---------- BOOTSTRAP ADJUSTMENTS ---------- */
.row.vertical-center{
  height: 100%;
  }
/* ---------- /BOOTSTRAP ADJUSTMENTS ---------- */
@media only screen and (min-width:576px){
.sml{
  display: none !important;
  }
.sml-inline{
  display: none !important;
  }
.lrg{
  display: block !important;
  }
.lrg-inline{
  display: inline-block !important;
  }
.half-page-left{
  width: 50%;
  padding-right: 9.5px;
  padding-left: 24px;
  }
.content{
  padding: 24px 34px;
  }
.content.full-page{
  padding: 24px 0px;
  display: flex;
  align-items: stretch;
  }
.half-page-right{
  display: block;
  position: absolute;
  width: 100%;
  margin: 0px auto;
  left: 0px;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  }
.content.middle-big-pad{
  padding: 36px 36px 36px 36px;
  }
}

@media only screen and (min-width:768px){
h1.pull-left{
  margin-left: -4px;
  }
.sml-1{
  display: none !important;
  }
.sml-1-inline{
  display: none !important;
  }
.lrg-1{
  display: block !important;
  }
.lrg-1-inline{
  display: inline-block !important;
  }
.content{
  padding: 24px 55px;
  }
.content-top.large-adjust{
  padding-top: 199px !important;
  }
.content.bottom{
  padding: 24px 55px 60px 55px;
  }
.content.middle-big-pad{
  padding: 55px 55px 55px 55px;
  }
.half-page-right{
  padding: 0px 55px;
  }
.image-full::after {
  display: block;
  width: 51vw;
  padding-right: 24px;
  right: -51vw;
}
.vertical-center-block{
  top: 24%;
  transform: translateY(-25%);
  }
  .vertical-center-block.blk{
    top: 36%;
    transform: translateY(-25%);
    }
.white-text{
  color:rgb(246, 245, 242);
  }
.e-comerce-link-2.blk-bg::before{
  background-color: rgba(0, 0, 0, 1);
  }
.e-comerce-link-2.blk-bg:hover::before{
  background-color: rgba(0, 0, 0, 1);
  }
}
@media only screen and (min-width:992px){
.content{
  max-width: 843px;
  padding: 24px 0px;
  }
.content-top.large-adjust{
  padding-top: 199px !important;
  }
.content.bottom{
  padding: 24px 0px 60px 0px;
  }
.content.middle-big-pad{
  padding: 55px 0px 55px 0px;
  }
.right-align{
  float: right;
  }
.half-page-right{
  max-width: 843px;
  padding: 0px;
  }
.justify-content-end-lg{
  justify-content: flex-end!important;
  }
.vertical-center-block{
  top: 24%;
  transform: translateY(-25%);
  }
}
@media only screen and (min-width:1200px){
h1.pull-left{
  margin-left: -6px;
  }
.content{
  max-width: 987px;
  }
.content.middle-big-pad{
  padding: 96px 0px 96px 0px;
  }
.half-page-right{
  max-width: 987px;
  }
.half-page-text-block{
  position: relative;
  margin-top: 46vh;
  transform: translateY(-50%);
  }
.big-quote{
  font-size: 72px;
  line-height: 72px;
  }
  .vertical-center-block{
    top: 35%;
    transform: translateY(-25%);
    }
}
@media only screen and (min-width:1400px){
h1{
  font-size: 76px;
  }
h2{
  font-size: 55px;
  }
h3{
  font-size: 47px;
  }
h4{
  font-size: 34px;
}
.content{
  max-width: 1220px;
  } 
.content-top{
  padding-top: 240px !important;
  }
.content-top.large-adjust{
  padding-top: 240px !important;
  }
.content.top-block{
  padding-top: 240px !important;
  }
  .content.bottom-block{
    padding-bottom: 96px !important;
    }
.half-page-right{
  max-width: 1220px;
  top: 48%;
  }


}
@media only screen and (min-width:1400px){
.content{
  max-width: 1152px;
  }
.half-page-right{
  max-width: 1364px;
  }
.image-full::after {
  width: 59vw;
  right: -59vw;
  }
}
@media only screen and (min-width:1600px){
.content{
  max-width: 1364px;
  }
  h2{
    font-size: 76px;
    }
.half-page-right{
  max-width: 1364px;
  }
/* ----- BOOTSRTRAP ADDITIONS -----*/
.col-xxxl-1{
  flex: 0 0 auto;
  width: 8.33333333%;
}
.col-xxxl-2{
  flex: 0 0 auto;
  width: 16.66666667%;
}
.col-xxxl-3{
  flex: 0 0 auto;
  width: 25%;
}
.col-xxxl-4{
  flex: 0 0 auto;
  width: 33.33333333%;
}
.col-xxxl-5{
  flex: 0 0 auto;
  width: 41.66666667%;
}
.col-xxxl-6{
  flex: 0 0 auto;
  width: 50%;
}
.col-xxxl-7{
  flex: 0 0 auto;
  width: 58.33333333%;
}
.col-xxxl-8{
  flex: 0 0 auto;
  width: 66.66666667%;
}
.col-xxxl-9{
  flex: 0 0 auto;
  width: 75%;
}
.col-xxxl-10{
  flex: 0 0 auto;
  width: 83.33333333%;
}
.col-xxxl-11{
  flex: 0 0 auto;
  width: 91.66666667%;
}
.col-xxxl-12{
  flex: 0 0 auto;
  width: 100%;
}
/* ----- /BOOTSRTRAP ADDITIONS -----*/
}
@media only screen and (min-width:1800px){
.content{
  max-width: 1597px;
  }
.half-page-right{
  max-width: 1597px;
  }
/* ----- BOOTSRTRAP ADDITIONS -----*/
.col-xxxxl-1{
  flex: 0 0 auto;
  width: 8.33333333%;
}
.col-xxxxl-2{
  flex: 0 0 auto;
  width: 16.66666667%;
}
.col-xxxxl-3{
  flex: 0 0 auto;
  width: 25%;
}
.col-xxxxl-4{
  flex: 0 0 auto;
  width: 33.33333333%;
}
.col-xxxxl-5{
  flex: 0 0 auto;
  width: 41.66666667%;
}
.col-xxxxl-6{
  flex: 0 0 auto;
  width: 50%;
}
.col-xxxxl-7{
  flex: 0 0 auto;
  width: 58.33333333%;
}
.col-xxxxl-8{
  flex: 0 0 auto;
  width: 66.66666667%;
}
.col-xxxxl-9{
  flex: 0 0 auto;
  width: 75%;
}
.col-xxxxl-10{
  flex: 0 0 auto;
  width: 83.33333333%;
}
.col-xxxxl-11{
  flex: 0 0 auto;
  width: 91.66666667%;
}
.col-xxxxl-12{
  flex: 0 0 auto;
  width: 100%;
}
/* ----- /BOOTSRTRAP ADDITIONS -----*/
}

@media only screen and (min-width:2000px){
  .content{
    max-width: 1597px;
    }
  .half-page-right{
    max-width: 1597px;
    }
  /* ----- BOOTSRTRAP ADDITIONS -----*/
  .col-xxxxxl-11{
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxxxxl-1{
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  /* ----- /BOOTSRTRAP ADDITIONS -----*/
  }

@media only screen and (max-width:786px){
  .content.no-pad-sml{
    padding: 0px;
  }
  .col-clear-sml{
    display: none;
  }
}
@media only screen and (max-width:576px){
  .col-xxs-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
}
  .col-xxs-3{
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxs-4{
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxs-5{
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxs-6{
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxs-7{
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  
}

@media only screen and (max-width:428px){
  .col-xxs-2 {
    flex: 0 0 auto;
    width: 100%;
}
  .col-xxs-3{
    flex: 0 0 auto;
    width: 100%;
  }
  .col-xxs-4{
    flex: 0 0 auto;
    width: 100%;
  }
  .col-xxs-5{
    flex: 0 0 auto;
    width: 100%;
  }
  .col-xxs-6{
    flex: 0 0 auto;
    width: 100%;
  }
  .col-xxs-7{
    flex: 0 0 auto;
    width: 100%;
  }

  
}

/* LANDING PAGE */

.landing-text{
    opacity: 1;
    transition: all ease-in-out 300ms;
    }
.landing-text.off{
    opacity: 0;;
    }
/* LANDING PAGE */