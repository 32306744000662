.bg-scale{
    position: fixed;
    display: none;
    left: 0px;
    right: 0px;
    top: 0px;
    margin: 0px auto;
    height: 100vh;
    z-index: 9999999999;
    width: 100%;
    opacity: 0.6;
    padding: 0px 18px;
    pointer-events: none;
    }
.bg-scale.on{
    display:block;
    }
.bg-scale .row{
    height: 100vh;
    /*padding: 5.5px !important;*/
    }

.column{
    position: relative;
    display: block;
    height: 100%;
    }
.column::after{
    content: "";
    background-color: #bbb7ac;
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    }
@media only screen and (min-width: 576px){
    .bg-scale {
        padding: 0px 34px;
        }
    }
@media only screen and (min-width: 768px){
.bg-scale {
    padding: 0px 55px;
    }
}
@media only screen and (min-width:992px){
.bg-scale{
    max-width: 843px;
    padding: 18px 0px;
    }
}
@media only screen and (min-width:1200px){
.bg-scale{
    max-width: 987px;
    }   
}
@media only screen and (min-width:1400px){
.bg-scale{
    max-width: 1152px;
    }
}
@media only screen and (min-width:1600px){
.bg-scale{
    max-width: 1364px;
    }
}
@media only screen and (min-width:1800px){
.bg-scale{
    max-width: 1597px;
    }    
}