footer{
    position: relative;
    display: block;
    height: auto;
    width: 100%;
    z-index: 0;
}
footer .footer-container{
    position: relative;
    display: block;
    width: 100%;
    margin: 0px auto;
    padding: 0px 18px;
}
footer .footer-top{
    position: relative;
    display: block;
    width: 100%;
    padding: 18px 0px;
}
footer .footer-top .footer-logo{
    position: relative;
    display: inline-block;
    width: 199px;
    margin-left: -15px;
}
footer .footer-top .footer-logo-text{
    position: absolute;
    display: block;
    top: 26px;
    margin-left: 33px;
    text-transform: uppercase;
    font-size: 7px;
    letter-spacing: 0.96em;
    color: #fd1d86;
    }
footer .footer-top .social-media-links{
    position: relative;
    display: block;
    width: auto;
    margin-top: 2px;
    opacity: 0.6;
    }
footer .footer-top .social-media-link{
    position: relative;
    display: inline-block;
    width: 30px;
    right: 0px;
    margin-left: 9px;
    cursor: pointer;
    }
footer .footer-bottom{
    position: relative;
    display: block;
    width: 100%;
    margin: 0px auto;
    border-top: 1px solid #aeaca8;
    padding: 5px 0px 34px 0px;
    font-size: 8px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    }
footer .footer-bottom .left{
    font-size: inherit;
    text-align: left;
    }
footer .footer-bottom .right{
    font-size: inherit;
    text-align: right;
    }
@media only screen and (min-width:576px){
    footer .footer-container{
        padding: 0px 34px;
        } 
    }
@media only screen and (min-width:768px){
footer .footer-container{
    padding: 0px 55px;
    } 
}
@media only screen and (min-width:992px){
footer .footer-container{
    max-width: 843px;
    padding: 18px 0px;
    }
}
@media only screen and (min-width:1200px){
footer .footer-container{
    max-width: 987px;
    }
footer .footer-top .social-media-links{
    margin-top: 12px;
    }   
}
@media only screen and (min-width:1400px){
    footer .footer-container{
        max-width: 1152px;
        }    
    }
@media only screen and (min-width:1600px){
footer .footer-container{
    max-width: 1364px;
    }    
}
@media only screen and (min-width:1800px){
    footer .footer-container{
        max-width: 1597px;
        }    
    }
