.heroHeader{
    position: absolute;
    height: auto;
    width: min-content;
    padding: 0px;
    top: 31.1%;
    right: 19%;
    color: #fd1d86;
    }
.heroHeader h1>*{
    position: relative;
    display: block;
    padding: 0px !important;
    font-size: 96px;
    line-height: 1em;
    }
.heroHeader h1 .adj1{
    margin-top: 0px;
    margin-left: 51px;
    font-size: 48px;
    }
.heroHeader h1 .adj2{
    margin-top: -42px;
    font-size: 96px;
    }
.heroHeader h1 .adj3{
    margin-top: -28px;
    margin-left: 60px;
    font-size: 73px;
    }
.locationText{
    left: 63px;
    bottom: 15px;
    }
.contentTopParagraph{
    position: relative;
    display: block;
    height: fit-content;
    font-size: 18px;
    margin-top: 24px;
    }
.locationText{
    position: relative;
    left: 20.5%;
    bottom: 9px;
    }
.locationText .line1{
    position: relative;
    display: block;
    font-size: 18px;
    line-height: 18px;
    font-family: 'HCo Gotham SSm Book';
    }
.locationText .line2{
    font-size: 24px;
    text-transform: uppercase;
    font-family: 'HCo Gotham SSm Light'; 
    }
.swedishPrinceHeroPic{
    position: relative;
    z-index: -1;
    margin-left: -56px;
    margin-right: -56px;
    }
@media only screen and (min-width:996px){
.swedishPrinceHeroPic{
    margin-left: -7vw;
    margin-right: 0px;
    }
.contentTopParagraph{
    position: absolute;
    bottom: 5%;
    }  
}
@media only screen and (min-width:1200px){
.heroHeader h1 .adj1{
    margin-top: 0px;
    margin-left: 51px;
    font-size: 48px;
    }
.heroHeader h1 .adj2{
    margin-top: -42px;
    font-size: 96px;
    }
.heroHeader h1 .adj3{
    margin-top: -28px;
    margin-left: 60px;
    font-size: 73px;
    }
.locationText{
    left: 63px;
    bottom: 15px;
    }
.swedishPrinceHeroPic{
    margin-left: 0px;
    margin-right: 0px;
    }
}
@media only screen and (min-width:1400px){
    .contentTopParagraph{
        bottom: 5%;
        }
}
@media only screen and (min-width:1600px){
    .contentTopParagraph{
        bottom: 12.9%;
        }  
}
@media only screen and (min-width:1800px){
    .contentTopParagraph{
        bottom: 18%;
        }
}