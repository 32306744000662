.scale-button{
    position: fixed;
    display: block;
    top: 0px;
    left: 0px;
    height: 30px;
    width: 30px;
    background-color: transparent;
    cursor: pointer;
    }
header{
    position: fixed !important;
    display: block;
    top: 0px;
    height: 96px;
    padding-bottom: 20px;
    width: 100%;
    /*background-color: transparent;
    background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    transition: all ease-in-out 120ms;*/
    z-index: 1000;
    }
header.reduced{
    height: 60px;
    }
header.bg-color-on{
    padding-bottom: 0px;
    background-color: rgb(244, 242, 237);
    background-image: linear-gradient(rgba(244, 242, 237, 0), rgba(244, 242, 237, 0));
    }
header.dark{
    /*background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
    background-color: transparent;*/
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
    }
header .header-container{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: top;
    width: 100%;
    max-width: 1597px;
    margin: 0px auto;
    padding: 11px 18px;
    transition: all ease-in-out 300ms;
    }
header.reduced .header-container{
    padding-top: 9px;
    padding-bottom: 9px;
    }
.header-marker{
        position: absolute;
        height: 30px;
        width: 50%;
        bottom: 40px;
        /*background-color: red;*/
        } 
/* ----- LOGO -----*/
header .header-container .header-logo{
    position:relative;
    display: flex;
    height: auto;
    width: 233px;
    margin-left: -12px;
    transition: all ease-in-out 300ms;
    }
header.reduced .header-container .header-logo{
    width: 190px;
    margin-left: -16px;
    }
header .header-container .header-logo .light-logo{
    display: block;
    }
header .header-container .header-logo .dark-logo{
    display: none;
    }
header.bg-color-on .header-container .header-logo .light-logo{
    display: none;
    }
header.bg-color-on .header-container .header-logo .dark-logo{
    display: block;
    }
header .header-container .header-logo .header-logo-text{
    position: absolute;
    display: block;
    top: 36px;
    left: 50px;
    font-family: 'HCo Gotham SSm Light';
    text-transform: uppercase;
    font-weight: normal;
    font-size: 10px;
    letter-spacing: 0.79em;
    text-decoration: none !important;
    color: rgb(244, 242, 237);
    transition: all ease-in-out 300ms;
    }
header.reduced .header-container .header-logo .header-logo-text{
    font-size: 6.6px;
    letter-spacing: 0.9em;
    top: 25px;
    left: 41px;
    }

header .header-container .header-logo .header-logo-text.light{
    color: #fd1d86;
    }
header.dark .header-container .header-logo .header-logo-text{
    color: #fd1d86;
    }
.menu-label{
    display: none;
    }
/* ----- /LOGO -----*/
/* ----- DROP DOWN MENU BUTTON -----*/
header .header-container .menu-drop-down-btn{
    position: relative;
    display: flex;
    right: 0px;
    margin-right: 0px;
    height: 21px;
    width: 34px;
    cursor: pointer;
    z-index: 1002;
    margin-top: 7px;
    transition: all ease-in-out 300ms;
    }
header .header-container .menu-drop-down-btn i{
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: rgb(244, 242, 237);
    transition: all ease-in-out 300ms;
    }
header.dark .header-container .menu-drop-down-btn i{
    background-color: #7c7170;
    }
header .header-container .menu-drop-down-btn.light i{
    background-color: #7c7170;
    }
header .header-container .menu-drop-down-btn i:nth-child(1){
    top: 0px;
    }
header .header-container .menu-drop-down-btn i:nth-child(2){
    top: 50%;
    transform: translateY(-50%);
    }
header .header-container .menu-drop-down-btn i:nth-child(3){
    bottom: 0px;
    }
header .header-container .menu-drop-down-btn.close i:nth-child(1){
    transform: rotate(45deg);
    top: 9.5px;
    background-color: #fd1d86;
    }
header .header-container .menu-drop-down-btn.close i:nth-child(2){
    opacity:0;
    }
header .header-container .menu-drop-down-btn.close i:nth-child(3){
    transform: rotate(-45deg);
    bottom: 9.5px;
    background-color: #fd1d86;
    }
header .header-container .menu-drop-down-btn.light.close i:nth-child(1){
    transform: rotate(45deg);
    top: 9.5px;
    background-color: #fd1d86;
    }
header .header-container .menu-drop-down-btn.light.close i:nth-child(3){
    transform: rotate(-45deg);
    bottom: 9.5px;
    background-color: #fd1d86;
    }
/* ----- /DROP DOWN MENU BUTTON -----*/
/* ----- DROP DOWN MENU ----- */
.drop-down-menu{
    position: fixed;
    left: 0px;
    right: 0px;
    display: block;
    height: 100%;
    width: 100%;
    background-color: transparent;
    z-index: 1001;
    top: -100%;
    transition: all ease-in-out 500ms;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: rgb(244, 242, 237);
    overflow: hidden;
    background-position: 112% 100%;
    }
.drop-down-menu.background-1{
    background-image: url(../../images/menu-background-5-sml.webp);
    }
.drop-down-menu.background-2{
    background-image: url(../../images/menu-background-6-sml.webp);
    }
.drop-down-menu.background-3{
    background-image: url(../../images/menu-background-7-sml.webp);
    }
.drop-down-menu.background-4{
    background-image: url(../../images/menu-background-8-sml.webp);
    }
.drop-down-menu.background-5{
    background-image: url(../../images/menu-background-3-sml.webp);
    }
.drop-down-menu.on{
    top: 0%;
    }
.drop-down-menu .menu{
    position:relative;
    display: block;
    margin: 0px auto;
    }
.drop-down-menu .menu ul{
    position:relative;
    display: block;
    top: 0px;
    left: 0px;
    padding: 48px 0px 0px 21px;
    }
.drop-down-menu .menu ul .nav-link{
    position: relative;
    display: block;
    color:#fd1d86 !important;
    font-size: 55px;
    line-height: 55px;
    font-family: bodoni-pt-variable, sans-serif;
    font-style: normal;
    font-variation-settings: "opsz" 60, "wght" 700;
    padding: 8px 0px;
    cursor: pointer;
    width: max-content;
    text-decoration: none;
    transition: all ease-in-out 500ms !important;
    pointer-events: none;
    opacity: 0;
    }
.drop-down-menu .menu ul .nav-link.show{
    opacity: 1;
    }
.drop-down-menu.on .menu ul .nav-link{
    pointer-events: all;
    transition: all ease-in-out 500ms;
    margin-top: 24px;
    }
.drop-down-menu .menu ul .nav-2.on1::after{
    content: "";
    position: absolute;
    height: 6px;
    width: 3000px !important;
    top: 63px;
    left: 0%;
    background-color: #fd1d86 !important;
    transition: all ease-in-out 500ms;
    }
.drop-down-menu .menu ul .nav-3.on1::after{
    content: "";
    position: absolute;
    height: 6px;
    width: 3000px !important;
    top: 63px;
    left: 0%;
    background-color: #fd1d86 !important;
    transition: all ease-in-out 500ms;
    }
.drop-down-menu.on .menu ul .nav-link::after{
    content: "";
    position: absolute;
    height: 4px;
    width: 0px;
    top: 55px;
    left: 3px;
    background-color: #fd1d86 !important;
    transition: all ease-in-out 500ms;
    }
.drop-down-menu .menu ul .nav-link:hover::after{
    transition: all ease-in-out 500ms;
    width: 3000px !important;
    font-weight:normal ;
    }
.drop-down-menu .menu ul .nav-link.active{
    color: #fd1d86 !important;
    font-weight:normal ;
    width: 100%;
    }
.drop-down-menu .menu ul .nav-link.active::after{
    content: "";
    position: absolute;
    height: 4px;
    width: 3000px;
    top: 55px;
    left: 5px;
    }
.drop-down-menu .menu ul .nav-link.on::after{
    width: 3000px;
    }
/* ----- /DROP DOWN MENU ----- */
/* ----- COLLECTION MENU ----- */
.collection-menu{
    position: absolute;
    top: 60px;
    right: 98px;
    display: none;
    opacity: 0;
    width: 210px;
    padding: 18px;
    white-space: nowrap !important;
    background-color: rgb(244, 242, 237);
    transition: all ease-in-out 300ms;
    }
.collection-menu.on{
    display: block;
    }
.collection-menu.on2{
    opacity: 1;
    }
.collection-menu .collection-menu-link{
    display: block;
    position: relative;
    font-family: bodoni-pt-variable, sans-serif;
    font-style: normal;
    font-variation-settings: "opsz" 60, "wght" 700;
    color: #ada59b !important;
    text-decoration: none !important;
    font-size: 16px;
    padding: 0px 18px 9px 0px;
    line-height: 18px;
    transition: all ease-in-out 200ms;
    }
.collection-menu-link:hover{
    color: #fd1d86 !important;
    }
.collection-menu-link.active{
    color: #fd1d86 !important;
    }
/* ----- /COLLECTION MENU ----- */
/* ----- EVENTS MENU ----- */
.events-menu{
    position: absolute;
    top: 60px;
    right: 98px;
    display: none;
    width: 210px;
    padding: 18px;
    white-space: nowrap !important;
    background-color: rgb(244, 242, 237);
    }
.events-menu.on{
    display: block;
    }
.events-menu.on2{
    opacity: 1;
    }
.events-menu .events-menu-link{
    display: block;
    position: relative;
    font-family: bodoni-pt-variable, sans-serif;
    font-style: normal;
    font-variation-settings: "opsz" 60, "wght" 700;
    color: #ada59b !important;
    text-decoration: none !important;
    font-size: 16px;
    padding: 0px 18px 9px 0px;
    line-height: 18px;
    transition: all ease-in-out 200ms;
    }
.events-menu-link:hover{
    color: #fd1d86 !important;
    }
.events-menu-link.active{
    color: #fd1d86 !important;
    }
/* ----- EVENTS MENU ----- */
/* ----- SUB MENU COLLECTION ----- */
.sub-menu{
    display: none;
    position: relative;
    text-align: left;
    height: auto;
    opacity: 0;
    margin-bottom: 14px;
    transition: all ease-in-out 300ms;
    transition-delay: 300ms;
    }
.sub-menu.on1{
    display: block;
    }
.sub-menu.on2{
    opacity: 1;
    }
.sub-nav-link{
    display: block;
    position: relative;
    font-family: 'HCo Gotham SSm Book';
    color: #fd1d86 !important;
    text-decoration: none;
    font-size: 16px;
    letter-spacing: 0.08em;
    font-weight: 500;
    text-transform: uppercase;
    margin: 11px 18px 0px 2px;
    text-decoration: none !important;
    width: 100%;
    }  
/* ----- /SUB MENU COLLECTION ----- */
@media only screen and (min-width:576px){
header .header-container{
    padding: 21px 34px;
    }   
}
@media only screen and (min-width:768px){
header .header-container{
    padding: 21px 55px;
    }
.drop-down-menu .menu ul{
    padding: 48px 0px 0px 0px;
    }
.drop-down-menu{
    background-position: -32% 100% !important;
    }
.drop-down-menu.background-1{
    background-image: url(../../images/menu-background-5.webp);
    }
.drop-down-menu.background-2{
    background-image: url(../../images/menu-background-6.webp);
    }
.drop-down-menu.background-3{
    background-image: url(../../images/menu-background-7.webp);
    }
.drop-down-menu.background-4{
    background-image: url(../../images/menu-background-8.webp);
    }
.drop-down-menu.background-5{
    background-image: url(../../images/menu-background-3.webp);
    }
}

@media only screen and (min-width:824px){
.drop-down-menu{
    background-position: -23% 100% !important;
    }
}
@media only screen and (min-width:992px){
header .header-container{
    max-width: 843px;
    padding: 18px 0px;
    }
header .header-container .header-logo{
    margin-left: -19px;
    }
.drop-down-menu{
    background-position: -14% 100% !important;
    }
.drop-down-menu .menu {
    max-width: 843px;
    }
.collection-menu{
    right: 54px;
    }
.events-menu{
    right: 54px;
    }
header .header-container .menu-label{
    position: absolute;
    display: block;
    top: 26px;
    text-align: right;
    right: 46px;
    font-size: 29px !important;
    font-family: bodoni-pt-variable, sans-serif;
    font-style: normal;
    font-variation-settings: "opsz" 60, "wght" 700;
    color: rgb(244, 242, 237) !important;
    line-height: 1em;
    transition: all ease-in-out 300ms;
    }
header.reduced .header-container .menu-label{
    top: 14px;
    }
header.dark .header-container .menu-label{
    color: #7c7170 !important;
    }
header.bg-color-on .header-container .menu-label{
    color: #7c7170 !important;
    }
}
@media only screen and (min-width:1200px){
header .header-container{
    max-width: 987px;
    padding: 21px 0px;
    }
header .header-container .header-logo{
    width: 288px;
    margin-left: -23px;
    }
header .header-container .header-logo .header-logo-text{
    top: 44px;
    left: 62px;
    font-size: 12px;
    letter-spacing: 0.83em;
    }
.menu-drop-down-btn{
    margin-top: 10px;
    }
.drop-down-menu{
    background-position: -8% 100% !important;
    }
.drop-down-menu .menu {
    max-width: 987px;
    }
.drop-down-menu .menu ul{
    padding: 96px 0px;
    }
.drop-down-menu .menu ul .nav-link{
    font-size: 76px;
    line-height: 76px;
    padding: 0px 0px;
    }
.drop-down-menu.on .menu ul .nav-link::after{
    height: 6px;
    width: 0%;
    top: 63px;
    left: 6px;
    }
.drop-down-menu .menu ul .nav-link.active::after{
    width: 3000px;
    }
.collection-menu.on{
    display: block;
    }
.events-menu.on{
    display: block;
    }
/*
/* ----- COLLECTION MENU ----- */
.collection-menu{
    top: 60px;
    right: -126px;
    }
/* ----- COLLECTION MENU ----- */
/* ----- EVENTS MENU ----- */
.events-menu{
    top: 60px;
    right: -126px;
    }
/* ----- EVENTS MENU ----- */
.menu-label{
    top: 30px;
    right: 46px;
    font-size: 29px !important;
    }
}
@media only screen and (min-width:1400px){
header .header-container{
    max-width: 1152px;
    }
.collection-menu{
    right: -112px;
    }
.events-menu{
    right: -112px;
    }
.collection-menu .collection-menu-link{
    font-size: 18px;
    }
.drop-down-menu .menu {
    max-width: 1152px;
    }  
}
@media only screen and (min-width:1600px){
header .header-container{
    max-width: 1364px;
    }
.collection-menu{
    right: -94px;
    }
.events-menu{
    right: -94px;
    }
.drop-down-menu .menu {
    max-width: 1364px;
    }
.drop-down-menu .menu ul{
    padding: 120px 0px 0px 0px;
    }   
}
@media only screen and (min-width:1800px){
header .header-container{
    max-width: 1597px;
    }
.collection-menu{
    right: -75px;
    }
.events-menu{
    right: -75px;
    }
.drop-down-menu .menu {
    max-width: 1597px;
    }   
}