.heroHeader{
    position: relative;
    height: auto;
    width: max-content;
    padding: 0px;
    color: #fd1d86;
    margin-left: -3px;
    margin-top: 24px;
    }
.heroHeader h1>*{
    position: relative;
    display: block;
    padding: 0px;
    line-height: 1em;
    }
.heroHeader h1 .adj1{
    margin-top: 0px;
    margin-left: 129px;
    font-size: 42px;
    }
.heroHeader h1 .adj2{
    margin-top: -16px;
    font-size: 60px;
    }
.palmTree{
    position: absolute;
    width: 70px;
    right: -3px;
    top: -59px;
    }
.palmText{
    position: relative;
    margin-right: 0px;
    right: 0px;
    margin-top: 330px;
    text-shadow: 1px 1px 1px rgb(244, 242, 237);
    }
.topImageBlock{
    border: 2px solid #eabfba;
    }
.imageBtn{
    position: relative;
    cursor: pointer;
    }
.imageBtnNames{
    position: relative;
    text-align: center;
    font-family: bodoni-pt-variable, sans-serif;
    font-style: normal;
    font-variation-settings: "opsz" 60, "wght" 800;
    color: #fd1d86;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    }
.quotePullBack{
    margin-left: -3px;
    }
.sketch{
    position: relative;
    margin: 0px auto;
    width: 48px;
    opacity: 0.8;
    }
.contentTopParagraph{
    padding-top: 0px;
    padding-bottom: 24px;
    }
@media only screen and (min-width:480px){
    .heroHeader h1 .adj1{
        margin-top: 0px;
        font-size: 72px;
        margin-left: 156px;
        }
    .heroHeader h1 .adj2{
        margin-top: -27px;
        left: 0px;
        font-size: 96px;
        }
    .palmTree{
        width: 105px;
        right: -9px;
        top: -65px;
        }
}      
@media only screen and (min-width:768px){
.heroHeader{
    margin: 0px auto;
    }
.palmTreeBottom{
    position: absolute;
    height: 446px;
    width: 330px;
    right: 110px;
    }
.palmText{
    position: relative;
    right: 0px;
    }
}
@media only screen and (min-width:1200px){
.quotePullBack{
    margin-left: -8px;
    }
.heroHeader{
    margin-left: auto;
    }
.palmTreeBottom{
    width: 400px;
    height: 562px;
    bottom: 10px;
    right: -150px;
    }
.palmText{
    
    }
}