@font-face {

    font-family: 'BenguiatCaslonSpec';
    src: url('../../Webfonts/BenguiatCaslonRegular/font.woff2') format('woff2'),
    url('../../Webfonts/BenguiatCaslonRegular/font.woff') format('woff');
    unicode-range: U+0049;
  
  }

  .BenguiatCaslonSpec{
    font-family: 'BenguiatCaslonSpec';
  }
  /*.markBelfordPageBg1{
    background-image: url(../../images/menu-background-1.webp);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.markBelfordPageBg1::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
}*/
.markContent{
    padding-top: 199px !important;
    }
.heroHeader{
    position: relative;
    height: auto;
    width: max-content;
    padding: 0px;
    color: #fd1d86;
    }
    .heroHeader h1 .adj1{
        margin-top: -8px;
        font-size: 73px;
        line-height: 84px;
        margin-left: 56px;
        }
    .heroHeader h1 .adj2{
        margin-top: -24px;
        margin-left: 0px;
        font-size: 96px;
        line-height: 84px;
        }
@media only screen and (min-width:1200px){
    .markContent{
        padding-top: 240px !important;
        }
    .heroHeader{
        margin-left: 0px;
        }
    .heroHeader h1 .adj1{
        margin-top: -8px;
        font-size: 96px;
        line-height: 84px;
        margin-left: 56px;
        }
    .heroHeader h1 .adj2{
        margin-top: -24px;
        margin-left: 0px;
        font-size: 96px;
        line-height: 84px;
        }
    }
