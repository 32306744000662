.heroHeader{
    position: relative;
    height: auto;
    width: max-content;
    padding: 0px;
    margin: 24px 0px 24px -5px;
    color: #fd1d86;
    }
.heroHeader h1>*{
    padding: 0px !important;
    position: relative;
    display: block;
    font-size: 96px;
    line-height: 1em;
    font-size: 60px;
    }
.heroHeader h1 .adj1{
    margin-top: 0px;
    font-size: 48px;
    margin-left: 58px;
    }
.heroHeader h1 .adj2{
    margin-top: -41px;
    margin-left: 0px;
    font-size: 96px;
    }
.heroHeader h1 .adj3{
    margin-top: -10px;
    margin-left: 38px;
    font-size: 96px;
    line-height: 0.5em;
    }