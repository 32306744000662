.heroHeader{
    position: relative;
    height: auto;
    width: max-content;
    padding: 0px;
    margin: 24px 0px 24px -5px;
    color: #fd1d86;
    }
.heroHeader h1>*{
    position: relative;
    display: block;
    padding: 0px !important;
    font-size: 96px;
    line-height: 1em;
    }
.heroHeader h1 .adj1{
    margin-top: 0px;
    font-size: 48px;
    margin-left: 67px;
    }
.heroHeader h1 .adj2{
    margin-top: -43px;
    margin-left: 0px;
    font-size: 96px;
    }
.heroHeader h1 .adj3{
    margin-top: -6px;
    margin-left: 41px;
    font-size: 120px;
    line-height: 0.5em;
    }
@media only screen and (min-width:768px){

}