.articleContainer{
    /*margin-bottom: 12px;*/
}
.article{
    color: #7c7670;
    /*border-top: 1px solid #7c7670;
    border-bottom: 1px solid #7c7670;
    margin-bottom: 12px;*/
    }
.article::after{
    content: '';
    position: relative;
    display: block;
    background-color: #7c7670;
    height: 1px;
    width: 100%;
    margin-bottom: 16px;
    }
.articleLast::after{
    content: '';
    position: relative;
    display: block;
    background-color: #7c7670;
    height: 0px;
    width: 100%;

    }
.articlePeriodical{
    font-family: 'HCo Gotham SSm Book';
    color: #7c7670; 
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;
    }
.articleDate{
    font-family: 'HCo Gotham SSm Light';
    color: #7c7670; 
    font-size: 12px;
    line-height: 20px;
    margin-top: -8px;
    text-transform: uppercase;
    }
.articleTitle{
    font-family: bodoni-pt-variable, sans-serif;
  font-style: normal;
  font-variation-settings: "opsz" 60, "wght" 700;
  color: #7c7670;
  padding-bottom: 12px;
    }