.quotePullBack{
    margin-left: -6px;
    }
.sketch{
    position: relative;
    margin: 0px auto;
    width: 48px;
    opacity: 0.8;
    }
.pageAnchor{
    position: absolute;
    display: block;
    height: 96px;
    width: 96px;
    margin-top: -72px;
    /*background-color: red;*/
    }
.contentTopParagraph{
    padding-top: 0px;
    text-align: left;
    font-size: 18px;
    }
.halfPageRight {
    display: block;
    position: absolute;
    width: 100%;
    max-width: 1220px;
    margin: 0px auto;
    left: 0px;
    right: 0px;
    top: 48%;
    transform: translateY(-50%);
    }
.fullBleedFlushLeft {
    position: relative;
    display: block;
    }
.specsBlock{
    position: relative;
    }
.specsBlock .catagoryType{
    text-transform: uppercase;
    font-family: 'HCo Gotham SSm Medium';
    font-size: 12px;
    clear: both;
    }
.specsBlock .catagoryName{
    clear: both;
    margin: -6px 0px 6px 0px;
    }
.clickMeTag{
    color: rgb(244, 242, 237);
    background-color: #fd1d86;
    position: absolute;
    display: block;
    font-size: 21px;
    text-transform: uppercase;
    line-height: 0.94em;
    text-align: right;
    padding: 10px 48px 18px 12px;
    top: 48px;
    left: -124px;
    font-family: 'HCo Gotham SSm Medium';
    font-family: 'HCo Gotham SSm Light';
    }
.clickMeTag::after{
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-bottom: 20px solid #fd1d86;
    border-right: 20px solid transparent;
    bottom: 0px;
    right: -20px;
    }
.clickMeTag::before{
content: '';
position: absolute;
display: block;
width: 0;
height: 0;
border-bottom: 28px solid rgb(244, 242, 237);
border-right: 28px solid transparent;
bottom: -3px;
right: -28px;
    }
@media only screen and (min-width:576px){
}
@media only screen and (min-width:768px){
.fullBleedFlushLeft {
    /*width: 66.66666667vw;
    margin-left: calc(66.66666667% - 66.66666667vw);*/
    width: 83.33333333vw;
    margin-left: calc(83.33333333% - 83.33333333vw);
    padding-left: 24px;
    }
.fullBleed {
    width:62vw;
    margin-left: calc(100% - 56.5vw);
    }
}
@media only screen and (min-width:992px){
.fullBleedFlushLeft {
    width: 58.33333333vw;
    margin-left: calc(58.33333333% - 58.33333333vw);
    padding-left: 24px;
    }
.fullBleed {
    width:50vw;
    margin-left: calc(100% - 50vw);
    }
.fullBleed::after {
    width: 50vw;
    margin-right: calc(50% - 50vw);
    padding-right: 24px;
    background-color: #000;
    }
}
@media only screen and (min-width:1200px){
.quotePullBack{
    margin-left: -11px;
    }
.halfPageRight {
    display: block;
    position: absolute;
    width: 100%;
    max-width: 1220px;
    margin: 0px auto;
    left: 0px;
    right: 0px;
    top: 48%;
    transform: translateY(-50%);
    }
.fullBleedFlushLeft {
    width: 50vw;
    margin-left: calc(50% - 50vw);
    padding-left: 24px;
    }
   /* 
.fullBleed {
    width: 50vw;
    margin-left: calc(50% - 50vw);
    }
    */
.fullBleed {
    width:50vw;
    margin-left: calc(100% - 50vw);
    }
/*
.fullBleed::after {
    content: '';
        width: 50vw;
        padding-right: 24px;
        background-color: #000;
        display: block;
        height: 100%;
        position: absolute;
        top: 24px;
        right: 24px;
    }*/
}
@media only screen and (min-width:1400px){  
.fullBleed {
    width: 42vw;
    margin-left: calc(100% - 50vw);
    }  
}
@media only screen and (min-width:1550px){
}
@media only screen and (min-width:1800px){
}